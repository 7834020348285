import { render, staticRenderFns } from "./HomeComponent.vue?vue&type=template&id=40382dd1&"
import script from "./HomeComponent.vue?vue&type=script&lang=js&"
export * from "./HomeComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_@vue+compiler-sfc@3.2.36_css-loader@6.7.1_vue-template-compiler@2.6.14_webpack@5.72.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports